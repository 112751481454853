import axios from 'axios';
import { ACCOUNT_API_URL, DESIGN_API_URL } from '../../lib/environment.js';

const customers = () =>  {

	return {
	  create: (data) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				axios({
					url: `${ACCOUNT_API_URL}/customers`
					, method: 'POST'
					, cache: 'no-cache'
					, data: data
					, signal: controller.signal
				})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data.result);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
			});
		},

		read: (limit, offset, queryParams) => {
			return new Promise((resolve, reject) => {
				limit = limit || 500;
				offset = offset || 0;

				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				axios({
					url: `${ACCOUNT_API_URL}/customers?limit=${limit}&offset=${offset}&q={"isActive":true, "subscription.planId": {"$in":["enterprise","team"]}}`
					, method: 'GET'
					, cache: 'no-cache'
					, signal: controller.signal
				})
				.then(resp => {
					clearTimeout(timeout);

					resolve(resp.data)
				})
				.catch(err => {
					clearTimeout(timeout);

					reject(err);
				});
			});
		},

		getCurrentPrefs: () => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/customers/CURRENT/prefs`
					, method: 'GET'
					, cache: 'no-cache'
					, signal: controller.signal
				})
				.then(resp => {
					clearTimeout(timeout);

					resolve(resp.data)
				})
				.catch(err => {
					clearTimeout(timeout);

					reject(err);
				});
			})
		}
	}
};

const singleton = customers();
Object.freeze(singleton);

export default singleton;
