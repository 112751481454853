import axios from "axios";
import { FILESTORE_API_URL } from "../../lib/environment.js";

import { newAbortSignalFunc } from "../api";
import { apiUrlToFindEntriesInFolder } from "../apiFunctions";

const timeoutAmount = 180000; //Aborts request after 30 seconds

// NOTE: This is a duplicate of 'entries' and should be removed
//!DEPRECATED! Use 'entries' instead
//
const brandAssets = () => {
  return {
    // MARK: Read
    /**
     *
     * @param {object} queryParams
     * @param {number|string} limit 10
     * @param {number|string} offset 48 (usually divisible 12 for the grid)
     * @returns object
     */
    read: async (queryParams, limit, offset) => {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrlToFindEntriesInFolder('brand-assets', queryParams.folderPath, limit, offset, queryParams.search),
          method: "GET",
          cache: "no-cache",
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // MARK: Read Folders
    /**
     *
     * @param {string} folderId 123abc
     * @param {string} traverseDirection prev (up)| curr (current)| next (down)
     * @returns object
     */
    readEntries: async (folderId, traverseDirection) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${FILESTORE_API_URL}/entries/:key/relEntries.folder?:key=${folderId}&generation=${traverseDirection}`,
          method: "GET",
          cache: "no-cache",
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //MARK: Update
    /**
     *
     * @param {array} itemIdArray [asd123, 123abc, 321cba]
     * @returns object|array
     */
    // NOTE: 'isHidden' is inherited from the parent entry (implicit),
    // but the entry itself can also be 'isHidden' (persisted)
    //
    // WARNING: DO NOT save the 'isHidden' value from the parent entry
    //
    update: (itemIdArray, isHidden) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${FILESTORE_API_URL}/entries/:itemIdArray`,
          method: "PATCH",
          cache: "no-cache",
          data: {
            ':itemIdArray': itemIdArray,
            isHidden,
          },
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //MARK: Create Folder Path
    /**
     *
     * @param {string} pathName /some/path
		 * @param {boolean} isHidden true or false
     * @returns object
     */
    createFolderPath: (pathName, isHidden) => {
      return new Promise((resolve, reject) => {
				// TODO: Handle 'isHidden'
        axios({
          url: `${FILESTORE_API_URL}/entries.folder`,
          method: "POST",
          cache: "no-cache",
          data: { path: `brand-assets/${pathName}`, isHidden },
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // MARK: - Update Path / Rename
    // This is for the Move or Rename functionality
    /**
     *
     * @param {array} idArray [abc123, 123abc, 321cba]
     * @param {string} pathName /some/path
     * @returns object
     */
    updatePath: (itemId, dstPath) => {
      return new Promise((resolve, reject) => {
        //key/ path
        axios({
          url: `${FILESTORE_API_URL}/jobs.entry.relocation`,
          method: "POST",
          cache: "no-cache",
          data: {
            src: {
              keys: itemId,
            },
            dstPath: dstPath,
            runInForeground: true
          },
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //MARK: Delete
    /**
     *
     * @param {array} idArray [abc123, 123abc, 321cba]
     * @returns object
     */
    delete: (idArray) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${FILESTORE_API_URL}/jobs.entry.deletion`,
          method: "POST",
          cache: "no-cache",
          data: {
            src: {
              keys: idArray, //NOTE: can be a single id or an array of id's
            },
            runInForeground: true //idArray.length <= 10 // this number is arbitrary
          },
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            if (resp.data.status === 0) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // MARK: Move / Copy
    /**
     *
     * @param {idArray} itemId [asd123, acb123]
     * @param {string} pathName /some/path
     * @param {boolean} isCopy true or false
     * @returns object
     */
    move: (idArray, dstPath, isCopy = false) => {
      // Note can "move" into an non-existing folder and it will create a new folder
      return new Promise((resolve, reject) => {
        axios({
          url: `${FILESTORE_API_URL}/jobs.entry.${isCopy ? 'duplication' : 'relocation'}`,
          method: "POST",
          cache: "no-cache",
          data: {
            src: {
              keys: idArray, //NOTE: can be a single id or an array of id's
            },
            dstPath: `${dstPath}/`,
            runInForeground: true // idArray.length <= 10 // this number is arbitrary
          },
          timeout: timeoutAmount,
          signal: newAbortSignalFunc(timeoutAmount),
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  };
};

const singleton = brandAssets();
Object.freeze(singleton);

export default singleton;
