/* eslint-disable no-undef */
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { GrowthBook } from '@growthbook/growthbook-react';
import { isDev } from './environment.js';

const getCache = ()=>{
	let d = new Date();
	let growthbookData;

	if(window.sessionStorage.getItem('growthbook') !== 'undefined'){
		growthbookData = JSON.parse(window.sessionStorage.getItem('growthbook'));

		if(!isNil(growthbookData?.timeout) && !isNil(growthbookData?.features) && growthbookData?.timeout > d.getTime()){
			return growthbookData.features;
		}else{
			return;
		}
	}else{
		return;
	}
}

const setCache = (features)=>{
	let d = new Date();

	let growthbookData = {
		timeout: d.getTime() + 300000 //5 minutes
		, features: cloneDeep(features)
	};

	window.sessionStorage.setItem('growthbook', JSON.stringify(growthbookData));

	return growthbookData
}

const growthbookCache = getCache();

const getGrowthbook = async ()=>{
	if(!isNil(growthbookCache)){
		//console.log('getGrowthbook', 'cached');

		// Create a GrowthBook instance from the cache
		return new GrowthBook({
			features: cloneDeep(growthbookCache)
		});
	}else{
		//console.log('getGrowthbook', 'fetch');

		// Create a GrowthBook instance
		let gb = new GrowthBook({
			apiHost: 'https://cdn.growthbook.io',
			clientKey: (isDev ? 'sdk-uy2U4JW79Rx46pj' : 'sdk-21hEDuJGwy0GTQ80'),
			enableDevMode: true
		});

		//load the features
		try{
			await gb.loadFeatures({
				autoRefresh: false,
				timeout: 5000
			});

			let features = {};
			let gbFeatures = gb.getFeatures();

			for(let feature in gbFeatures){
				features[feature] = {
					defaultValue: gbFeatures[feature].defaultValue
				}

				if(!isNil(gbFeatures[feature].rules)){
					features[feature].rules = cloneDeep(gbFeatures[feature].rules);
				}
			};

			setCache(features);
		}

		catch(e){
			console.error('Growthbook Error', e)
		}

		return gb
	}
}

export const growthbook = await getGrowthbook();
export const features = Object.keys(growthbook.getFeatures());
