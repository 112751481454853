import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { isNil } from "lodash";
import { ACCOUNT_APP_ID } from "../lib/environment.js";

const defaultAccess = {
	canLogin: false,
	isSystemAdmin: false,
	isSuperAdmin: false,
	isSuperUser: false,
	isAdmin: false,
	isStaff: false
};

export const getAccess = (user) => {
	let purview;
	let customerNumber;

	// No user object passed in, so we need to get the user from the JWT
	if(!user){
		//TODO: Maybe refactor this to use the global state instead of cookies
		const bayToken = Cookies.get('bayToken');
		const jwt = !isNil(bayToken) ? jwt_decode(bayToken) : null;

		purview = jwt?.session.user.purview[ACCOUNT_APP_ID] || jwt?.session.user.purview['DEFAULT'];
		customerNumber = jwt?.session.user.customer.idNumber;
	}else{
		purview = user?.purview?.[ACCOUNT_APP_ID] || user?.purview?.['DEFAULT'];
		customerNumber = user?.customer?.idNumber;
	}

	// Set our default access levels
	let access = {...defaultAccess};

	// No purview means the user is not logged in
	if(!isNil(purview)){

		// User can login
		// TODO: Check for DEFAULT in the future...?
		access.canLogin = (purview.length !== 0) && (purview.indexOf('user') <= -1);

		// Customer level access
		access.isAdmin = (purview.indexOf('admin') !== -1);
		access.isStaff = (purview.indexOf('staff') !== -1);

		// Level access
		/*
		* sys (only backend)
		* globalAdmin
		* admin
		* user
		*/
		// TODO: Clean this up and match the backend
		if(!isNil(customerNumber)){
			access.isSystemAdmin = (purview.indexOf('sys') !== -1) || (purview.indexOf('systemAdmin') !== -1);
			// Can see all templates
			access.isSuperAdmin = (purview.indexOf('superadmin') !== -1 || purview.indexOf('globalAdmin') !== -1);
			// Can only see created templates for customers
			// NOTE: this is poorly defined and not currently in use. EX: Contract designers ?
			access.isSuperUser = (purview.indexOf('superuser') !== -1) || (purview.indexOf('superUser') !== -1);
		}
	}

	// Global role is any role that has access to customers
	access.isGlobalRole = (
		access.isSystemAdmin ||
		access.isSuperAdmin ||
		access.isSuperUser
	);

	// Set the role
	access.role = (
		access.isSystemAdmin
		? 'System Admin'
		: (
			access.isSuperAdmin
			? 'Super Admin'
			: (
				access.isSuperUser
				? 'Super User'
				: (
					access.isAdmin
					? 'Admin'
					: (
						access.isStaff
						? 'Staff'
						: ''
					)
				)
			)
		)
	);

	// Return the access object
	return access;
};
