/* eslint-disable */
import axios from 'axios';
import { isNil } from 'lodash';
import { ACCOUNT_API_URL } from '../../lib/environment.js';
import { getAccess } from '../access';

export default {
	login: async (opts) => {
		let timer = null;

		return new Promise((resolve, reject) => {
			let { email, password } = opts;

			const loginController = new AbortController();

			timer = setTimeout(() => {
				loginController.abort();
			}, 180000);

			axios({
				url: `${ACCOUNT_API_URL}/GET/logins/AUTHZ/users`,
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					username: email,
					password: password
				},
				signal: loginController.signal,
			})
			.then((resp) => {
				clearTimeout(timer);

				if(resp.data.result.length === 0){
					resolve({ status: 1, message: 'Invalid Email or Password' });
					return;
				}

				if(resp.data.result.length > 1){
					resolve({ status: 1, message: 'This user has multiple accounts, and cannot login at this time.' });
					return;
				}

				const sessionController = new AbortController();

				timer = setTimeout(() => {
					sessionController.abort();
				}, 180000);

				return axios({
					url: `${ACCOUNT_API_URL}/sessions`,
					method: 'POST',
					cache: 'no-cache',
					headers: {
						'Content-Type': 'application/json',
					},
					data: {
						user: resp.data.result[0]._id,
						password: password
					},
					signal: sessionController.signal,
				});
			})
			.then((resp) => {
				clearTimeout(timer);

				let access;

				if(!isNil(resp.data.result.session.user)){
					access = getAccess(resp.data.result.session.user);
				}

				if(!access || !access.canLogin){
					reject({status:1, message:`User account is not authorized to login to this application. Please contact an administrator to grant this user account login access.`});
				}else{
					resolve(resp.data);
				}
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					resolve({ status: 1, message: 'Invalid Email or Password' });
					return;
				}

				clearTimeout(timer);

				reject({ status: 1, message: err.message });
			});
		});
	},
	refresh: async () => {
		return new Promise((resolve, reject) => {
			const controller = new AbortController();

			let timeout = setTimeout(() => {
				controller.abort();
			}, 180000);

			axios({
				url: `${ACCOUNT_API_URL}/sessions/CURRENT`,
				method: 'GET',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json',
				},
				signal: controller.signal,
			})
				.then((resp) => {
					clearTimeout(timeout);

					let access;

					if (!isNil(resp.data.result.session.user)) {
						access = getAccess(resp.data.result.session.user);
					}

					// console.log('access', resp.data);

					if (!access || !access.canLogin) {
						reject({
							status: 1,
							message: `User account is not authorized to login to this application. Please contact an administrator to grant this user account log in access.`,
						});
					} else {
						resolve(resp.data);
					}
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject({ status: 1, message: err.message });
				});
		});
	},
};
