import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	show: false
	, type: 'error'
	, title: 'Notification!'
	, body: 'Notification Body'
	, size: 'sm'
	, backdrop: true
	, redirectOnOk: '/'
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		setNotificationState: (state, action) => {
			return {...state, ...action.payload};
		},
		resetNotificationState: () => {
			return initialState;
		}
	}
});

// Action creators are generated for each case reducer function
export const { setNotificationState, resetNotificationState } = notificationSlice.actions;

export const getNotificationState = (state) => state.notification;

export default notificationSlice.reducer;