import axios from "axios";
import Cookies from 'js-cookie';

import entries from './apis/entries';
import files from './apis/files';

import login from './apis/login';
import tags from './apis/tags';
import tagNamespaces from './apis/tagNamespaces';
import templates from './apis/templates';
import categories from './apis/categories';
import brandLibrary from './apis/brandLibrary';
import brandAssets from './apis/brandAssets';
import customers from './apis/customers';

import kvstore from './apis/kvstore';

export const newAbortSignalFunc = (timeoutMs) => {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

axios.interceptors.request.use(function (config) {
	const bayToken = Cookies.get('bayToken');
	//console.log('interceptors.request', config, bayToken);

	if(bayToken) config.headers.Authorization = `Bearer ${bayToken}`

	return config;
}, function (error) {
	return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
	// console.log('interceptors.response', response);

	if(response.data && response.data.meta && response.data.meta.bayToken) {
		Cookies.set('bayToken', response.data.meta.bayToken, {domain: '.backatyou.com' });
	}

	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// remove the cookie and redirect to login page if the token is invalid
	if(error.response?.status === 401){
		sessionStorage.clear();
		Cookies.remove('bayToken', {domain: '.backatyou.com' });

		if (window.location.pathname !== '/login') {
			window.location.href = '/login';
		}
	}

	 //console.log(error)

	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

export default {
	entries
	, files
	, login
	, tags
	, tagNamespaces
	, templates
	, categories
	, brandLibrary
	, brandAssets
	, customers
	, kvstore
};
