/* eslint-disable */
import axios from 'axios';
import { DESIGN_API_URL } from '../../lib/environment.js';

export default {
	upload: (fileFolder, data, onProgress)=>{
		return new Promise((resolve, reject)=>{

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${DESIGN_API_URL}/files/${fileFolder}`
				, method: 'POST'
				, cache: 'no-cache'
				, data: data
				, onUploadProgress : (progressEvent) => {
					const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

					if(onProgress) onProgress(progress);
				},
				signal: controller.signal
			})
			.then(resp => {
				clearTimeout(timeout);

				if(resp.data.status === 0){
					resolve(resp.data)
				}else{
					reject(resp.data);
				}
			})
			.catch(err => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	},

	list: (fileFolder, continuationToken, onProgress)=>{
		return new Promise((resolve, reject)=>{

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${DESIGN_API_URL}/files/${fileFolder}` + (continuationToken ? `/${continuationToken}` : ``)
				, method: 'GET'
				, cache: 'no-cache'
				, onDownloadProgress : (progressEvent) => {
					const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

					if(onProgress) onProgress(progress);
				},
				signal: controller.signal
			})
			.then(resp => {
				clearTimeout(timeout);

				if(resp.data.status === 0){
					resolve(resp.data)
				}else{
					reject(resp.data);
				}
			})
			.catch(err => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	}
}