import { configureStore } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import { getSessionState, setSessionState } from '../modules/utils';

import { getTemplateState, setTemplateState, resetTemplateState, getDefaultTemplateState } from './slices/templateSlice';
import { getTemplatesState, setTemplatesState, updateTemplatesState, resetTemplatesState } from './slices/templatesSlice';
import { getHeadlineState, setHeadlineState, resetHeadlineState, getDefaultHeadlineState } from './slices/headlineSlice';
import { getNavState, setNavState, resetNavState } from './slices/navSlice';
import { getNotificationState, setNotificationState, resetNotificationState } from './slices/notificationSlice';
import { getUserState, setUserState, resetUserState } from './slices/userSlice';
import { getActingAsState, setActingAsState, resetActingAsState } from './slices/actingAsSlice';
import { getModalState, setModalState, resetModalState } from './slices/modalSlice';

import reducers from './reducers';

//MIDDLEWARE
const localStorageMiddleware = ({ getState }) => {
	return (next) => (action) => {
		const result = next(action);
		//DO NOT MUTATE
		const { template, user, actingAs, headline, notification } = getState();
		//OVERRIDES
		//console.log('rehydrate');
		if (window.location.pathname === '/login' || window.location.pathname === '/logout') {
			//setSessionState('globalState', { template: getDefaultTemplateState(), headline: getDefaultHeadlineState(), notification, user, actingAs });
			setSessionState('globalState', { template: getDefaultTemplateState('Post'), headline: getDefaultHeadlineState(), notification });
		} else {
			//setSessionState('globalState', { template, headline, notification, user, actingAs });
			setSessionState('globalState', { template, headline, notification });
		}
		return result;
	};
};

const reHydrateStore = () => {
	if (sessionStorage.getItem('globalState') !== null) {
		return getSessionState('globalState'); // re-hydrate the store
	}
};

const store = configureStore({
	devTools: true,
	reducer: reducers,
	preloadedState: reHydrateStore(),
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware)
});

store.subscribe(() => {
	setSessionState('globalState', store.getState());
});

const globalState = {
	//redux
	useDispatch,
	store,
	//template
	useTemplateState: () => {
		let templateState = useSelector(getTemplateState);

		return templateState;
	},
	getDefaultTemplateState,
	setTemplateState,
	resetTemplateState,

	//templates
	useTemplatesState: () => {
		let templatesState = useSelector(getTemplatesState);

		return templatesState;
	},
	setTemplatesState,
	updateTemplatesState,
	resetTemplatesState,

	//templates
	useHeadlineState: () => {
		let templateState = useSelector(getHeadlineState);

		return templateState;
	},
	getDefaultHeadlineState,
	setHeadlineState,
	resetHeadlineState,

	//nav
	useNavState: () => {
		let navState = useSelector(getNavState);

		return navState;
	},
	setNavState,
	resetNavState,

	//notifications
	useNotificationState: () => {
		let notificationState = useSelector(getNotificationState);

		return notificationState;
	},
	setNotificationState,
	resetNotificationState,

	//user
	useUserState: () => {
		let userState = useSelector(getUserState);

		return userState;
	},
	setUserState,
	resetUserState,

	//Acting As
	useActingAsState: () => {
		let actingAsState = useSelector(getActingAsState);

		return actingAsState;
	},
	setActingAsState,
	resetActingAsState,

	//modal

	useModalState: () => {
		let modalState = useSelector(getModalState);

		return modalState;
	},
	setModalState,
	resetModalState
};

export default globalState;
