import { combineReducers } from 'redux'

import template from '../slices/templateSlice'
import templates from '../slices/templatesSlice'
import headline from '../slices/headlineSlice'
import nav from '../slices/navSlice'
import notification from '../slices/notificationSlice'
import user from '../slices/userSlice'
import actingAs from '../slices/actingAsSlice'
import modal from '../slices/modalSlice'
import toast from '../slices/toastSlice'

export default combineReducers({
	toast,
	template,
	templates,
	headline,
	nav,
	notification,
	user,
	actingAs,
	modal,
});
