import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
    setModalState: (state, action) => {
			return action.payload;
		},
		resetModalState: (state, action) => {
			return initialState;
		}
	}
});

// Action creators are generated for each case reducer function
export const { setModalState, resetModalState } = modalSlice.actions;

export const getModalState = (state) => state.modal;

export default modalSlice.reducer;