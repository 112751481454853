import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useRouteError
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { Button } from "react-bootstrap";
import { isEmpty, isNil } from "lodash";

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { ErrorBoundary } from "react-error-boundary";

import BayLoader from "../bay-ui/src/BayLoader";
import BayLogo from "../bay-ui/src/BayLogo";
import BayModal from "../bay-ui/src/BayModal";
import BayToast from '../bay-ui/src/BayToast';
import { closeToast } from '../state/slices/toastSlice';

import globalState from "../state/global";

import api from "../modules/api";

import { getAccess } from "../modules/access";

import { useGrowthBook } from "@growthbook/growthbook-react";

const App = lazy(() => import("../components/App"));
//TODO: unify naming scheme for all components
const Login = lazy(() => import("../components/pages/login"));
const Index = lazy(() => import("../components/pages/index"));
const ReadyToShare = lazy(() => import("../components/pages/ReadyToShare"));
const BrandAssets = lazy(() => import("../components/pages/BrandAssets"));
const Template = lazy(() => import("../components/pages/template"));
const Templates = lazy(() => import("../components/pages/templates/templates"));
//const Headline = lazy(() => import("../components/pages/headline"));
//const Headlines = lazy(() => import("../components/pages/headlines"));
//const Settings = lazy(() => import("../components/pages/settings"));
const NotFoundPage = lazy(() => import("../components/pages/notFound"));

const Router = () => {
  const [showSessionExpiringModal, setShowSessionExpiringModal] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const userState = globalState.useUserState();
  const notificationState = globalState.useNotificationState();

  const globalDispatch = globalState.useDispatch();

  let bayToken = Cookies.get("bayToken");

	const gb = useGrowthBook();

  // TODO: Check if the user is an enterprise user or has an enterprise subscription
	// Refactor this to be more reliable, this HACK property will go away someday
  const hasReadyToShare = (userState?.HACK_isEnterprise) || (userState?.subscription?.planId === 'enterprise');

  let sessionExpiring = false;
  let sessionExpired = false;
  let expireInterval;

  useEffect(() => {
    if (sessionExpired) {
      clearInterval(expireInterval);
      setShowSessionExpiredModal(true);
    }
  }, [sessionExpired, expireInterval]);

  useEffect(() => {
    if (!isNil(bayToken)) {
      handleSessionExpiringModalClose();
    }
  }, []);

  const logout = () => {
    sessionStorage.clear();
    Cookies.remove("bayToken", { domain: ".backatyou.com" });
    // We use window here to force a hard refresh
    window.location.href = "/login";
  };

  const handleSessionExpiringModalClose = async () => {
    await api.login
      .refresh()
      .then((resp) => {

        if (resp.status === 0) {
          setShowSessionExpiringModal(false);

          if (isNil(userState)) {
            globalDispatch(globalState.setUserState(resp.result.session.user));
            globalDispatch(globalState.setActingAsState(resp.result.session.user));
          }
        } else {
          logout();
        }
      })
      .catch((e) => {
        //logout();
        console.error("refresh error", e);
      });
  };

  if (!isEmpty(bayToken) && !isNil(bayToken)) {
    expireInterval = setInterval(async () => {
      const jwt = jwt_decode(bayToken);

      const jwtRefresh = jwt?.expRefresh * 1000;

      //if the refresh token has expired already then log the user out
      sessionExpired = new Date().getTime() > jwtRefresh;

      //if the refresh token is less than 5 minutes away from expiring
      sessionExpiring = jwtRefresh - new Date().getTime() <= 300000;

      if (sessionExpired) {
        console.log("sessionExpired", sessionExpired);
        setShowSessionExpiringModal(false);
        setShowSessionExpiredModal(true);
      }

      if (sessionExpiring) {
        console.log("sessionExpiring", sessionExpiring);
        setShowSessionExpiringModal(true);
      }
    }, 5000);
  }

  const logError = (error, info) => {
    // TODO: Do something with the error, e.g. log to an external API
    console.warn("Design Admin Error Boundary:", error, info);
  };

	const FallbackErrorElement = ({ error, resetErrorBoundary }) =>{
		// NOTE: Call resetErrorBoundary() to reset the error boundary and retry the render.

		return (
			<>
				<nav
					className="navbar bg-light fixed border-bottom"
					style={{ height: 65 }}
				>
					<div className="container">
						<span className="navbar-brand">
							<BayLogo platform="social" size={"30px"} logoStyle="full" />
						</span>
					</div>
				</nav>
				<main>
					<div className="container">
						<div
							className="row d-flex align-items-center justify-content-center w-100"
							style={{ paddingTop: 70 }}
						>
							<div className="col-md-6 col-12">
								<h1 className="maintenance-page-header mb-3">
									Oops, Something Went Wrong!
								</h1>

								<p>
									We apologize, but it seems like the page you're trying to
									connect to is experiencing technical difficulties. The link
									may be broken or the action you were attempting didn't work as
									expected.
								</p>

								<p>
									Rest assured, our team is already working to fix the problem.
									In the meantime, you can try “Refresh Page” or "Return to
									Homepage" button below.
								</p>

								<div className="d-flex">
									<Button
										variant="secondary"
										className="my-3"
										onClick={
											resetErrorBoundary
												? resetErrorBoundary
												: () => window.location.reload()
										}
									>
										Refresh Page
									</Button>
									<Button
										variant="outline-secondary"
										className="ms-3 my-3"
										href="/login"
									>
										Return to Log In page
									</Button>
								</div>
							</div>
							<div className="col-xxl-5 col-md-6 d-none d-md-flex align-items-center justify-content-center">
								<img
									src={window.location.origin + "/assets/img/Error.svg"}
									alt="error"
									className="w-100"
									style={{ maxHeight: 540 }}
								/>
							</div>
						</div>
					</div>
				</main>
			</>
		);
	}

  const ErrorElement = () => {
    let error = useRouteError();

    return <FallbackErrorElement error={error} />;
  };

  // TODO: Refactor the routes to be more DRY and programmatic
  const router = gb?.ready && createBrowserRouter(
    createRoutesFromElements(
       <Route element={<App />}>

        <Route
          errorElement={<ErrorElement />}
          path="/"
          element={
            !isEmpty(bayToken) && !isNil(bayToken) ? (
              getAccess().isGlobalRole ? (
                <Navigate to="/templates" />
              ) : (
                <Suspense
                  fallback={
                    <div className="designer-skeleton">
                      <BayLoader />
                    </div>
                  }
                >
                  {hasReadyToShare ? <Navigate to="/ready-to-share" /> : <Index />}
                </Suspense>
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          errorElement={<ErrorElement />}
          path="/ready-to-share"
          element={
            !isEmpty(bayToken) && !isNil(bayToken) ? (
              getAccess().isGlobalRole ? (
                <Navigate to="/templates" />
              ) : (
                <Suspense
                  fallback={
                    <div className="designer-skeleton">
                      <BayLoader />
                    </div>
                  }
                >
                  <ReadyToShare />
                </Suspense>
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* <Route
          errorElement={<ErrorElement />}
          path="/headline"
          element={<Headline />}
        >
          <Route path=":action" element={<Headline />} />
        </Route> */}

        {/* <Route
          errorElement={<ErrorElement />}
          path="/headlines"
          element={<Headlines />}
        /> */}

        <Route
          errorElement={<ErrorElement />}
          path="/template"
          element={
            !isEmpty(bayToken) && !isNil(bayToken) ? (
              <Suspense
                fallback={
                  <div className="designer-skeleton">
                    <BayLoader />
                  </div>
                }
              >
                <Template />
              </Suspense>
            ) : (
              <Navigate to="/login" />
            )
          }
        >
          <Route path=":action" element={<Template />}>
            <Route path=":actionOptions" element={<Template />} />
          </Route>
        </Route>

        <Route
          errorElement={<ErrorElement />}
          path="/templates"
          element={
            !isEmpty(bayToken) && !isNil(bayToken) ? (
              <Suspense
                fallback={
                  <div className="designer-skeleton">
                    <BayLoader />
                  </div>
                }
              >
                <Templates />
              </Suspense>
            ) : (
              <Navigate to="/login" />
            )
          }
        >
          <Route path=":navKey" element={<Templates />}>
            <Route path=":navLabel" element={<Templates />} />
          </Route>
        </Route>

        <Route
          errorElement={<ErrorElement />}
          path="/brand-assets"
          element={
            !isEmpty(bayToken) && !isNil(bayToken) ? (
              <Suspense
                fallback={
                  <div className="designer-skeleton">
                    <BayLoader />
                  </div>
                }
              >
                <BrandAssets />
              </Suspense>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* <Route
          errorElement={<ErrorElement />}
          path="/settings"
          element={<Settings />}
        /> */}

        <Route
          errorElement={<ErrorElement />}
          path="/login"
          element={
            isEmpty(bayToken) && isNil(bayToken) ? (
              <Suspense
                fallback={
                  <div className="designer-skeleton">
                    <BayLoader />
                  </div>
                }
              >
                <Login />
              </Suspense>
            ) : (
              <Navigate to="/" />
            )
          }
        />

        <Route
          errorElement={<ErrorElement />}
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
    )
  );

  const ToastWrapper = () => {
    const dispatch = useDispatch();
    const toast = useSelector((state) => state.toast);
    return(
      <BayToast
        onClose={() => dispatch(closeToast())}
        {...toast}
      />
    )
  }

  return (
    <ErrorBoundary
      FallbackComponent={FallbackErrorElement}
      onError={logError}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
        window.location.reload();
      }}
    >
      <RouterProvider
        router={router}
        fallbackElement={
          <div className="designer-skeleton">
            <BayLoader />
          </div>
        }
      />

      <ToastWrapper />

      {/* global notifications modal */}
      {!isNil(notificationState) && (
				<BayModal
					title={notificationState.title}
					size={notificationState.size}
					backdrop={notificationState.backdrop}
					show={notificationState.show}
					closeButton={false}
					variant={'danger'}
					buttonText={'Ok'}
					hideCancel={true}
					submitFunc={() => notificationState.redirectOnOk ? globalDispatch(globalState.resetNotificationState()) : null}
				>
					<span>
						{notificationState.body}
					</span>
				</BayModal>
      )}

      {/* global session expiring/expired modal */}
      {showSessionExpiredModal ?
					<BayModal
						title={'Session Expired'}
						backdrop={true}
						show={showSessionExpiredModal}
						closeButton={false}
						hideCancel={true}
						buttonText={'Log In'}
						variant={'danger'}
						submitFunc={() => logout()}
					>
						<span>
							Your session has expired. You will now be redirected to log in.
						</span>
					</BayModal>
				: showSessionExpiringModal ?
					<BayModal
						title={'Session Expiring Soon'}
						backdrop={true}
						show={showSessionExpiringModal}
						closeButton={false}
						hideCancel={true}
						buttonText={'Ok'}
						submitFunc={() => handleSessionExpiringModalClose()}
						>
						<span>
							Your session is about expire. Please click 'Ok' to continue or you <br/>will lose unsaved changes and have to log in.
						</span>
					</BayModal>
				: false
			}

    </ErrorBoundary>
  );
};

export default Router;
