import axios from 'axios';
import { FILESTORE_API_URL } from '../../lib/environment.js';

import { apiUrlToFindEntriesInFolder } from '../apiFunctions';

// NOTE: This is a duplicate of 'entries' and should be removed
//!DEPRECATED! Use 'entries' instead
//
const brandLibrary = () => {
  return {
    read: async (queryParams, limit, offset) => {
			// GET entries?glob=design/*
				return new Promise((resolve, reject) => {

					const controller = new AbortController();

					let timeout = setTimeout(()=>{
						controller.abort();
					}, 180000);

					axios({
						url: apiUrlToFindEntriesInFolder('brand-library', queryParams.folderPath, limit, offset, queryParams.search, 'dtUpd:desc')
						, method: 'GET'
						, cache: 'no-cache'
						, signal: controller.signal
					})
					.then(resp => {
						clearTimeout(timeout);

						resolve(resp.data)
					})
					.catch(err => {
						clearTimeout(timeout);

						reject(err);
					});
				});
    },

		//MARK: Update
    /**
     *
     * @param {array} itemIdArray [asd123, 123abc, 321cba]
     * @returns object|array
     */
    // NOTE: 'isHidden' is inherited from the parent entry (implicit),
    // but the entry itself can also be 'isHidden' (persisted)
    //
    // WARNING: DO NOT save the 'isHidden' value from the parent entry
    //

		update: (itemIdArray, isHidden) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				axios({
					url: `${FILESTORE_API_URL}/entries/:itemIdArray`
					, method: 'PATCH'
					, cache: 'no-cache'
					, data: {
            ':itemIdArray': itemIdArray,
            isHidden,
          }
					, signal: controller.signal
				})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
			});
		},

		updatePath: (itemId, pathName) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				//key/ path
				axios({
					url: `${FILESTORE_API_URL}/files/${itemId}/path`
					, method: 'PUT'
					, cache: 'no-cache'
					, data: {"path": pathName}
					, signal: controller.signal
				})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
			});
		},

		delete: (id) => {
			// DELETE entries/file_id
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				axios({
					url: `${FILESTORE_API_URL}/entries/${id}`
					, method: 'DELETE'
					, cache: 'no-cache'
					, signal: controller.signal
				})
				.then((resp) => {
					clearTimeout(timeout);

					if (resp.data.status === 0) {
						resolve(resp.data);
					} else {
						reject(resp.data);
					}
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
			});
		}
  }
};

const singleton = brandLibrary();
Object.freeze(singleton);

export default singleton;
