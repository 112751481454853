import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import { Image } from 'react-bootstrap-icons'

import GreyLogo from './BAY_Grey.svg';
import PinkLogo from './BAY_Pink.svg';
import IconLogo from './B_Grey.svg';
import PoweredByLogo from './BAY_PoweredBy_Grey.svg';

import ClientLogo from './ClientBAY.svg';
import FinancialLogo from './FinancialBAY.svg';
import SocialLogo from './SocialBAY.svg';
import DesignLogo from './DesignBAY.svg';

const width = (props) => {
	let displayHeight = 'auto';
	let displayWidth = 'auto';

	if (props.size && props.size.length) {
		if (props.size === 'large'){
			displayHeight = 'auto'
			displayWidth = '100%'
		} else if (props.size === 'small'){
			displayHeight = 'auto'
			displayWidth = '15%'
		} else {
			displayHeight = props.size
		}
	}

	return `
			width: ${displayWidth};
			height: ${displayHeight};
			justify-content: center;
	`;
};

const LogoImage = styled.img`
	${(props) => width(props)}
`;

const BayLogo = (props) => {
	const [imageError, setImageError] = useState(false)
	const { size, logoStyle, platform, source } = props;

	const checkSource = (sourceString) => {
		if (sourceString && sourceString.length > 0) {
			return true;
		}
	};

	const checkPlatform = (platform) => {
		let newSource = '';

		if (platform && platform.length > 0 && logoStyle === 'full') {
			switch (platform) {
				case 'client':
					newSource = ClientLogo;
					break;
				case 'financial':
					newSource = FinancialLogo;
					break;
				case 'social':
					newSource = SocialLogo;
					break;
				case 'design':
					newSource = DesignLogo;
					break;

				default:
					break;
			}
		} else if (logoStyle !== 'full') {
				switch (logoStyle) {
					case 'bay':
						newSource = PinkLogo
						break;
					case 'grey':
						newSource = GreyLogo
						break;
					case 'icon':
						newSource = IconLogo
						break;
					case 'powered':
						newSource = PoweredByLogo
						break;

					default:
						break;
			}
		}

		return newSource;
	};

	const setImage = (event) => {
		event.target.onerror = null
		setImageError(true)
		//event.target.src=`/global/images/empty-state/${cardImage ? 'card-image' : 'image'}.svg`
}

	return (
		!imageError ? (	<LogoImage
			src={
				checkSource(source) ? source : checkPlatform(platform)
			}
			className="App-logo"
			alt="logo"
			onError={(e) => setImage(e)}
			size={size}
		/>) : (
			<span className="logo-image">
					 <Image />
			</span>
	)
	);
};

BayLogo.defaultProps = {
	logoStyle: 'full',
	platform: 'client'
};

BayLogo.propTypes = {
	/**
		* Override the logo with your own URL
	 */
	source: PropTypes.string,
	/**
		* If you want to set a different size by adjusting the height (width is auto calculated)
		* using a percentage or pixel amount, or a predefined setting
	 */
	size: PropTypes.oneOfType([PropTypes.oneOf(['large', 'small']), PropTypes.string]),
	/**
		* Which style of branding to use
	 */
	logoStyle: PropTypes.oneOfType([PropTypes.oneOf(['grey', 'powered', 'full', 'icon', 'bay']), PropTypes.string]),
	/**
		* Which platform to show
	 */
	platform: PropTypes.oneOfType([PropTypes.oneOf(['financial', 'social', 'client', 'design']), PropTypes.string]),
};

export default BayLogo;
