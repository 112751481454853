import { FILESTORE_API_URL } from "../lib/environment.js";
// HACK: Should use lodash's version but this is a quick fix
import { escapeRegExp } from "./utils";

export const apiUrlToFindEntriesInFolder = (
    rootPath,
    folderPath,
    limit = null,
    offset = 0,
    search,
    sort = '_type:desc',
	entryType
  ) => {
    const glob = [rootPath, folderPath, (search && search !== '') ? '**' : '*'].filter(Boolean).join('/');

    const rgx = (search && search !== '') ? search?.split(/\s+/).map(escapeRegExp).join('.*') : null;

    const q = JSON.stringify(rgx ? {name: {$regex: rgx, $options: 'i'}} : {}); // {..., isHidden: false, ...}

    const entryTypeValue = entryType ? '.' + entryType : '';

    const url = new URL(`${FILESTORE_API_URL}/entries${entryTypeValue}`);

    url.search = new URLSearchParams({glob, limit, offset, q, sort}).toString();

    return url.href;
}