import axios from 'axios'
import { isDev, PLATFORM_APP_ID } from '../../lib/environment'

/**
 * data: {
		"val": {
				"foo": 22,
				"bar": 33,
				"baz": 44
			}
		}
 */

/**
 * URL construction
 * platform:component:userId
 * Example: ClientBAY:contactsGrid:ABCDE-XYZ
 */

//NOTE: This is semi-borrowed from RTK Query, perhaps refactor to use Ky instead?
/**
 * @param {string} url
 * @param {string} method
 * @param {object} data
 * @param {object} params
 * @returns {Promise<{data: any}>}
 * @private
 */
const axiosBaseQuery = async ({ url, method, data, params }) => {
	const baseUrl = `https://${isDev ? 'staging-' : ''}services.backatyou.com/kvstore/componentState/${PLATFORM_APP_ID}:`;

	try {
		const apiResponse = await axios({
			url: baseUrl + url,
			method,
			data,
			params,
			validateStatus: function (status) {
				return (status < 300 || status === 404) ? true : false; //NOTE: a 404 is valid. Resolve only if the status code is less than 300 or a 404
			}
		})
		// NOTE: This is semi-borrowed from RTK Query, we return 'undefined' for the 'data' property
		return { data: apiResponse.data.status !== 0 ? undefined : apiResponse.data.result}
	} catch (axiosError) {
		let err = axiosError
		return {
			error: {
				status: err.response?.status,
				data: err.response?.data || err.message,
			},
		}
	}
}

const kvstore = () => {
  return {
    read: async (options) => {
			const response = await axiosBaseQuery({
				url: `${options.component}:${options.userId}`,
				method: 'GET'
			})

			return response;
		},
    update: async (options) => {
			const response = await axiosBaseQuery({
				url: `${options.component}:${options.userId}`,
				method: 'PUT',
				data: {val: options?.data},
			})

			return response;
		},
  }
};

const singleton = kvstore();
Object.freeze(singleton);

export default singleton;
