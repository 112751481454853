import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	show: false,
	onExternalLinkClick: null,
	externalLinkText: null,
	text: 'Toast Message',
	variant: 'default',
	delay: 5000,
	autoHide: true,
	dismissible: true,
	addLineBreak: false
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast(state, action) {
      return {...initialState, ...action.payload}
    },
    closeToast(state, action) {
      return initialState
    }
  }
})

// Export the selectors
export const selectToast = (state) => state.toast;
// Export the dispatch actions
export const { setToast, closeToast } = toastSlice.actions
// Export the reducer
export default toastSlice.reducer
