import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
// 	active: true
// 	, birthDate: '1972-04-30'
// 	, city: 'Las Vegas'
// 	, country: 'US'
// 	, postalCode: '89129'
// 	, stateProvince: 'NV'
// 	, streetAddress: '9060 Wing Shadow Cir'
// 	, websiteUrl: 'https://www.backatyou.com'
// 	, email: 'lyle.karstensen@backatyou.com'
// 	, firstName: 'Lyle'
// 	, fullName: 'Lyle Karstensen'
// 	, imageUrl: 'https://bay-accounts.s3.amazonaws.com/profile-pictures/DE816D19-9AE5-4310-A2DC-E904E5122D15.jpg'
// 	, lastName: 'Karstensen'
// 	, middleName: 'Lee'
// 	, mobilePhone: '17029049900'
// 	, tenantId: '35446b3d-0838-4676-b36d-7413c9882873'
// 	, timezone: 'America/Los_Angeles'
// 	, id: '591dd6ee-5c87-4560-871d-d7c26013a5bf'
// 	, brandPrimaryColor: '#e043b1'
// 	, brandSecondaryColor: '#58595b'
// 	, logoDarkUrl: 'https://bay-design.s3.amazonaws.com/library/medium/design_images_63067_1666392500.8268.png'
// 	, logoLightUrl: 'https://bay-design.s3.amazonaws.com/library/medium/design_images_63067_1666392500.8268.png'
// 	, licenseNumber: ''
// 	, companyName:''
// };

const initialState = null;

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserState: (state, action) => {
			return action.payload;
		},
		resetUserState: (state, action) => {
			return initialState;
		}
	}
});

// Action creators are generated for each case reducer function
export const { setUserState, resetUserState } = userSlice.actions;

export const getUserState = (state) => state.user;

export default userSlice.reducer;
