import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from './lib/growthbook.js';

import Router from './router/Router.jsx';

import globalState from './state/global.js';

import './styles/design.scss';

// i18n init
import en from './bay-ui/src/i18n/en/en.json';
import common from './i18n/en/common.json';
import actions from './i18n/en/actions.json';
import placeholders from './i18n/en/placeholders.json';
import design from './i18n/en/design.json';
import { isDev } from './lib/environment.js';

let mergedEnglishTranslations = {
	...en,
	...common,
	...actions,
	...placeholders,
	...design
}

const resources = {
	en: {
		translation: mergedEnglishTranslations
	}
};

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: 'en',
	debug: isDev,
	lng: 'en',
	interpolation: {
		escapeValue: false // not needed for react as it escapes by default
	}
});

// HACK: quiet down some console errors from React and friends...
const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0]) || /recognize/.test(args[0])) return;
  error(...args);
};

ReactDOM
	.createRoot(document.getElementById("root"))
	.render(
		<Provider store={globalState.store}>
			<GrowthBookProvider growthbook={growthbook}>
				<Router />
			</GrowthBookProvider>
		</Provider>
);