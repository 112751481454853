import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, Toast } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import './BayToast.scss';

/**
 *
 * @deprecated use useToast hook instead
 * @example
 * const { showToast, hideToast } = useToast()
 */
const BayToast = ({
	text,
	show,
	onClose,
	className,
	variant,
	position,
	externalLinkText,
	onExternalLinkClick,
	delay,
	autoHide,
	dismissible,
	addLineBreak,
}) => {
	const uid = Math.floor(Math.random() * 0x100000).toString(16);
	return (
		<ToastContainer
			position={position}
			className={`position-fixed rounded p-3 bay-toast-${variant}`}
			bsPrefix="toast-container"
		>
			<Toast
				key={uid}
				bsPrefix="toast"
				animation
				autohide={autoHide}
				delay={delay}
				onClose={onClose}
				onExited={onClose}
				show={show}
				className={className ? className : ''}
				style={{ border: 'none' }}
			>
				<Toast.Body bsPrefix="toast-body" className="fs-6">
					<div className="d-flex justify-content-between px-2">
						<div className="d-flex justify-content-center align-items-center text-wrap fs-6">
							<p className="mb-0">
								{text}
								{externalLinkText && (
									<>
										{addLineBreak && <br />}
										<span
											className={`text-decoration-underline cursor-pointer ${
												addLineBreak ? false : 'mx-1'
											}`}
											style={{ textUnderlinePosition: 'under' }}
											onClick={onExternalLinkClick}
										>
											{externalLinkText}
										</span>
									</>
								)}
							</p>
						</div>
						{dismissible && (
							<div onClick={onClose} className="cursor-pointer">
								<AiOutlineClose size={18} />
							</div>
						)}
					</div>
				</Toast.Body>
			</Toast>
		</ToastContainer>
	);
};

BayToast.defaultProps = {
	text: 'Bay toast',
	variant: 'default',
	position: 'top-end',
	delay: 5000,
	autoHide: true,
	dismissible: true,
	addLineBreak: false,
};

BayToast.propTypes = {
	text: PropTypes.string,
	show: PropTypes.bool,
	onClose: PropTypes.func,
	className: PropTypes.string,
	variant: PropTypes.string,
	position: PropTypes.string,
	externalLinkText: PropTypes.string,
	onExternalLinkClick: PropTypes.func,
	delay: PropTypes.number,
	autoHide: PropTypes.bool,
	dismissible: PropTypes.bool,
	addLineBreak: PropTypes.bool,
};

export default BayToast;
