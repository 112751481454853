import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: 'Untitled Headline'
	, variant: 'headline'
	// TODO: make like templates, 0 or remove
	, owner: 'backatyou'
	, json: { width: 1080, height: 1080, fonts: [], pages: [{ id: 'fL3D7bAK_g', children: [], width: 'auto', height: 'auto', background: 'transparent', bleed: 0 }], unit: 'px', dpi: 300 }
	, status: 'draft'
	, history: []
	, tags: []
	// TODO: remove like templates
	, user: {}
};

export const headlineSlice = createSlice({
	name: 'headline',
	initialState,
	reducers: {
		setHeadlineState: (state, action) => {
			//null is not a valid
			if(!action.payload){
				return initialState;
			}else{
				return {...state, ...action.payload};
			}
		},
		resetHeadlineState: (state, action) => {
			return initialState;
			// console.log('Project Remove', action.payload)
		}
	}
});

// Action creators are generated for each case reducer function
export const { setHeadlineState, resetHeadlineState } = headlineSlice.actions;

export const getHeadlineState = (state) => state.headline
export const getDefaultHeadlineState = (state) => initialState

export default headlineSlice.reducer;