import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: 'Untitled Template',
	variant: 'design',
	json: {
		width: 1080,
		height: 1080,
		fonts: [],
		pages: [{ id: 'page_1', children: [], width: 'auto', height: 'auto', background: 'white', bleed: 0 }],
		unit: 'px',
		dpi: 96
	},
	status: 'draft',
	history: [],
	tags$Category: [],
	tags$General: [],
	directives: {},
	cfg: {
		global: {
			minPages: 1,
			maxPages: 10
		},
		customer: {
			minPages: 1,
			maxPages: 10
		},
		order: {
			countries: null,
			itemId: null,
		},
		canBeResized: true,
	}
};

export const templateSlice = createSlice({
	name: 'template',
	initialState,
	reducers: {
		setTemplateState: (state, action) => {
			//null is not a valid
			if (!action.payload) {
				return initialState;
			} else {
				return { ...state, ...action.payload };
			}
		},
		resetTemplateState: (state, action) => initialState
	}
});

// Action creators are generated for each case reducer function
export const { setTemplateState, resetTemplateState } = templateSlice.actions;

export const getTemplateState = (state) => state.template;
export const getDefaultTemplateState = (state) => initialState;

export default templateSlice.reducer;
