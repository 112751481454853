import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const navSlice = createSlice({
	name: 'nav',
	initialState,
	reducers: {
		setNavState: (state, action) => {
			return {...state, ...action.payload};
		},
		resetNavState: (state, action) => {
			return initialState;
		}
	}
});

// Action creators are generated for each case reducer function
export const { setNavState, resetNavState } = navSlice.actions;

export const getNavState = (state) => state.nav;

export default navSlice.reducer;