import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './BayModal.scss';

const BayModal = (props) => {
	const {
		children,
		buttonText,
		submitClass,
		cancelText,
		hideHeader,
		hideFooter,
		hideCancel,
		hideSubmit,
		fullscreen,
		closeButton,
		id,
		onHideFunc,
		show,
		submitFunc,
		variant,
		backdrop,
		title,
		scrollable,
		footer,
		disabled,
		size,
		grayBackground,
		noPadding,
		height,
		style,
		centered,
		translate,
		...restProps
	} = props;

	const t = translate ? translate : useTranslation().t;

	const showCancelConfirm = () => {
		onHideFunc(false);
	};

	return (
		<Modal
			id={id ? id : 'bay_generic_modal'}
			show={show}
			onHide={onHideFunc}
			className="bay-modal"
			backdrop={backdrop}
			scrollable={scrollable}
			size={size}
			fullscreen={fullscreen}
			backdropClassName="bay_modal_backdrop"
			style={style}
			centered={centered}
			{...restProps}
		>
			{!hideHeader && (
				<Modal.Header closeButton={closeButton}>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body
				style={{ maxHeight: !fullscreen ? (height ? height : 400) : '100%' }}
				className={`${grayBackground ? 'bg-25 ' : ''}${hideFooter ? 'no-footer ' : ''}${noPadding ? 'p-0' : ''}`}
			>
				{children}
			</Modal.Body>
			{!hideFooter && (
				<Modal.Footer
					className={`d-flex ${
						hideCancel || hideSubmit
							? 'justify-content-end'
							: 'justify-content-between'
					} ${hideCancel && hideSubmit ? 'border-0' : ''}`}
				>
					{footer ? (
						<>{footer}</>
					) : (
						<>
							{!hideCancel && (
								<Button
									className="cancel-button"
									variant={'outline-secondary'}
									onClick={() => showCancelConfirm()}
								>
									{cancelText ? cancelText : t('cancel')}
								</Button>
							)}
							{!hideSubmit && (
								<Button
									variant={variant ? variant : 'primary'}
									className={submitClass ? submitClass + ' ms-3' : 'ms-3'}
									onClick={submitFunc ? submitFunc : () => void 0}
									disabled={disabled}
								>
									{buttonText ? buttonText : t('submit')}
								</Button>
							)}
						</>
					)}
				</Modal.Footer>
			)}
		</Modal>
	);
};

BayModal.defaultProps = {
	show: false,
	centered: false,
};

BayModal.propTypes = {
	children: PropTypes.any,
	buttonText: PropTypes.string,
	submitClass: PropTypes.string,
	cancelText: PropTypes.string,
	closeButton: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onHideFunc: PropTypes.func,
	show: PropTypes.bool,
	submitFunc: PropTypes.func,
	variant: PropTypes.string,
	backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	title: PropTypes.any,
	size: PropTypes.string,
	scrollable: PropTypes.bool,
	disabled: PropTypes.bool,
	grayBackground: PropTypes.bool,
	hideCancel: PropTypes.bool,
	hideSubmit: PropTypes.bool,
	hideFooter: PropTypes.bool,
	hideHeader: PropTypes.bool,
	fullscreen: PropTypes.bool,
	noPadding: PropTypes.bool,
	footer: PropTypes.any,
	translate: PropTypes.func,
};

export default BayModal;
