import { parseInt, cloneDeep, isEmpty, isNil } from 'lodash';
import { dataFields } from './dataFields';
import buffer from 'buffer';

const { Buffer } = buffer;

export const regexIsImgTest = new RegExp(/(\/*).+\.(png|jpg|jpeg|gif|bmp|pdf|tiff|webm)$/i)

/* 	function escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
	} */

export const escapeRegExp = (val) => {
	const reRegExpChar = /[\\^$.*+?()[{}|]/g;


  const str = val?.toString?.() ?? val;

  if (str === undefined) { throw new TypeError(`${val} can't be stringified`) }

  return RegExp(reRegExpChar.source).test(str) ? str.replace(reRegExpChar, '[$&]') : str;
}

/**
 *
 * @param {string} dstPath some/pathName | somepath/filename.jpeg
 * @param {object} param1 {dstType: 'file' | 'folder'}
 * @returns boolean
 */
export const validateDstPath = (dstPath, {dstType} = {}) => {
  const tailRgx = {file: '(?<![ ./])', folder: '(?<![ .])'}[dstType];

  if (! tailRgx) { throw new Error('Invalid dstType') };

  const rgx = new RegExp(`^(?=[^ ./])(?: (?! )|[/](?![/])|[-!'()*.0-9A-Z_a-z])*${tailRgx}$`);

  return rgx.test(dstPath);
}

export const formatPhoneNumber = (phoneNumberString) => {
	let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		let intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return null;
};

export const hexToRGB = (h) => {
	let hexCode = h.replace('#', '');
	let rgb = [];

	if (hexCode.length === 6) {
		rgb = hexCode.match(/.{1,2}/g).map((code) => {
			return parseInt(code + '', 16);
		});
	}

	if (rgb.length === 0) {
		return null;
	}

	return { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 };
};

export const getSessionState = (stateName) => {
	try {
		const persistedState = window.sessionStorage.getItem(stateName);

		if (persistedState) {
			return JSON.parse(persistedState);
		} else {
			return;
		}
	} catch (e) {
		console.log(e);
	}
};

export const setSessionState = (stateName, state) => {
	try {
		return window.sessionStorage.setItem(stateName, JSON.stringify(state));
	} catch (e) {
		console.log('Storage error, attempting to evict data!', e);
		window.sessionStorage.removeItem(stateName);
	}
};

export const svgToPng = (svgUrl) => {
	return new Promise(function (resolve, reject) {
		try {
			const container = document.createElement('div');
			container.setAttribute('style', 'visibility: hidden;background: white;');
			document.body.appendChild(container);

			const svgImage = document.createElement('img');
			container.appendChild(svgImage);

			svgImage.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = svgImage.clientWidth;
				canvas.height = svgImage.clientHeight;
				const canvasCtx = canvas.getContext('2d');
				canvasCtx.drawImage(svgImage, 0, 0);
				const imgData = canvas.toDataURL('image/png');
				container.remove();
				resolve(imgData);
			};

			svgImage.src = svgUrl;
		} catch (e) {
			resolve(svgUrl);
		}
	});
};

export const createJsonFromData = (json, directives, data) => {
	let jsonData = cloneDeep(json);

	if (!directives) {
		return json;
	} else {
		jsonData.pages = jsonData.pages.map((page) => {
			page.children.map((layer) => {
				//if we have no directives for this layer just return it as is;
				if (!directives[layer.id]) {
					return layer;
				}

				if (layer.type === 'text') {
					layer.text = processTemplate(layer.text, directives[layer.id].text, data, directives[layer.id].format, directives[layer.id].maxLen) || layer.text;
					layer.color = processTemplate(layer.color, directives[layer.id].color, data) || layer.color;

					layer.fill = processTemplate(layer.fill, directives[layer.id].color, data) || layer.fill;
				}

				if (layer.type === 'svg') {
					layer.src = processTemplate(layer.src, directives[layer.id].src, data) || layer.src;
				}

				if (layer.type === 'image') {
					layer.src = processTemplate(layer.src, directives[layer.id].src, data) || layer.src;

					if (directives[layer.id] && directives[layer.id].src && (directives[layer.id].src.indexOf('user.image') !== -1 || directives[layer.id].src.indexOf('user.logo') !== -1)) {
						layer.src = `https://imageproxy.services.bkat.io/?url=${layer.src}&w=${layer.width}&h=${layer.height}&fit=contain`;

						//important to do this. since the image proxy is handling the cropping for us
						//set these values so that the design image being moved around does not affect the placement of the image.
						layer.cropWidth = 1;
						layer.cropHeight = 1;
						layer.cropX = 0;
						layer.cropY = 0;
					}

					if (directives[layer.id] && directives[layer.id].src && directives[layer.id].src.indexOf('listing.image') !== -1) {
						layer.src = `https://imageproxy.services.bkat.io/?url=${layer.src}&w=${layer.width}&h=${layer.height}&fit=cover&a=attention`;

						//important to do this. since the image proxy is handling the cropping for us
						//set these values so that the design image being moved around does not affect the placement of the image.
						layer.cropWidth = 1;
						layer.cropHeight = 1;
						layer.cropX = 0;
						layer.cropY = 0;

						// console.log(layer.src, layer);
					}
				}

				return layer;
			});

			return page;
		});
	}

	return jsonData;
};

const flattenData = (data) => {
	try {
		let newData = {};

		for (let field in data) {
			if (typeof data[field] === 'object') {
				data[field].forEach((record, index) => {
					newData[field + '_' + index] = record;
				});
			} else {
				newData[field] = data[field];
			}
		}

		return newData;
	} catch (e) {
		return data;
	}
};

const processTemplate = (currentValue, template, data, format, maxLen) => {
	if (!template || template === '') {
		return;
	}

	let replacements = template.match(/{{(.+?)}}/g);

	if (replacements) {
		let defaultValue = currentValue;

		if (replacements.length > 1) {
			defaultValue = '';
		}

		replacements.forEach((replacement, index) => {
			let token = replacement.replace(/{{|}}/g, '');

			if (!dataFields[token]) return;

			let dataField = dataFields[token].dataField;
			let dataFieldParts = dataField.split('.');
			let dataKey = dataField;
			let d = data || null;

			let ns = dataFieldParts.length > 1 ? dataFieldParts[0] : null;

			//if the dataField has a namespace in it
			if (ns) {
				d = data[ns] || null;
				dataKey = dataField.replace(`${ns}.`, '');
			}

			if (d) {
				d = flattenData(d);

				let value = d[dataKey] || defaultValue;

				//some values will be integers, so indexOf will not work
				if (!isNil(value) && value.typeof === 'string' && value?.indexOf('data:image/svg+xml;base64,') !== -1) {
					template = svgSetColor(defaultValue, value);
				} else {
					value = templateStringFormat(value, format, maxLen);

					if (dataKey.startsWith('brand')){
						value = colorToRGBA(value);
					}

					template = template.replaceAll(replacement, value);
				}
			}
		});

	} else {
		template = currentValue;
	}

	return template;
};

const templateStringFormat = (value, format, maxLen) => {
	// No value to process, abort
	if (isNil(value) || value === '') {
		return
	}

	switch (format) {
		case 'number':
			return parseInt(value).toLocaleString("en-US", { minimumFractionDigits: 0 });

		case 'decimal':
			return parseInt(value).toLocaleString("en-US", { minimumFractionDigits: 2 });

		case 'dollar':
			return parseInt(value).toLocaleString("en-US", {
				style: "currency"
				, currency: "USD"
				, minimumFractionDigits: 0
				, maximumFractionDigits: 0
			});

		case 'dollarCents':
			return parseInt(value).toLocaleString("en-US", {
				style: "currency"
				, currency: "USD"
				, minimumFractionDigits: 2
				, maximumFractionDigits: 2
			});

		case 'phoneNumber':
			let cleaned = ('' + value).replace(/\D/g, '');
			let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

			if (match) {
				let intlCode = (match[1] ? '+' + match[1] + ' ' : '');
				return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
			} else {
				return value;
			}

		case 'uppercase':
			return value.toString().toUpperCase();

		case 'lowercase':
			return value.toString().toLowerCase();

		case 'titlecase':
			let parts = value.toString().toLowerCase().split(' ');

			for (let i = 0; i < parts.length; i++) {
				parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
			}

			return parts.join(' ');

		default:
			return shortenText(value, maxLen);
	}
};

export const shortenText = (input, maxLen) => {
	if (!input || input.length === 0) return '';
	if (!maxLen || maxLen === 0) return input;

	let sentences = input.match(/[^.!?]+[.!?]+/g);

	if (!sentences) {
		return input.slice(0, maxLen);
	}

	let totalLen = 0;
	let truncated = [];

	for (const sentence of sentences) {
		//if the first element is longer then the maxLen, we need to shorten that sentence.
		if (truncated.length === 0 && sentence.trim().length > maxLen) {
			return sentence.slice(0, maxLen);
		}

		let s = (truncated.length !== 0 ? ' ' : '') + sentence.trim();

		if ((totalLen + s.length) <= maxLen) {
			truncated.push(s);
			totalLen += s.length;
		} else {
			break;
		}
	}

	return truncated.join('');
}

export const svgSetColor = (svg, color) => {
	try {
		svg = svgDataUriToXML(svg);

		svg = svg.replace(/fill="[^"]+"/g, `fill="${color}"`);
		svg = svg.replace(/stroke="[^"]+"/g, `stroke="${color}"`);

		return 'data:image/svg+xml;base64,' + Buffer.from(svg).toString('base64');
	}

	catch (e) {
		return svg;
	}
}

const colorToRGBA = (input) => {
	// console.log('colorToRGBA', 'Before', input);

	let m, r, g, b, a = 1;

	if (input.indexOf('rgba') !== -1) {
		m = input.match(/^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/i);

		if(!m) return;

		r = parseInt(m[1]);
		g = parseInt(m[2]);
		b = parseInt(m[3]);
		a = parseFloat(m[4]);
	} else if (input.indexOf('rgb') !== -1) {
		m = input.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);

		if(!m) return;

		r = parseInt(m[1]);
		g = parseInt(m[2]);
		b = parseInt(m[3]);
	} else if (input.indexOf('#') !== -1) {
		input = input.replace('#', '');

		r = parseInt(input.slice(0, 2), 16);
		g = parseInt(input.slice(2, 4), 16);
		b = parseInt(input.slice(4, 6), 16);
	} else {
		// console.log('colorToRGBA', 'After', input);
		return input;
	}

	let rgba = `rgba(${r}, ${g}, ${b}, ${a})`;

	// console.log('colorToRGBA', 'After', rgba);

	return rgba;
}

export const fixSVGLayers = (json) => {
	json.pages.map((page) => {
		return page.children.map((layer) => {
      /**
       * qr - is the default name of the layer and it set during QR layer creation.
       */
      if (layer.type === "svg" && layer.name !== "qr") {
        layer.src = svgColorToRGBA(layer.src)

        if (!isEmpty(layer.colorsReplace)) {
          layer.colorsReplace = fixColorsReplace(layer.colorsReplace)
        }
      }
      return layer
    });
	});

	return json;
}

export const fixColorsReplace = (colorsReplace) => {
	let replacements = {};

	for (let replaceKey in colorsReplace) {
		let key = colorToRGBA(replaceKey);
		let value = colorToRGBA(colorsReplace[replaceKey]);

		// console.log(replaceKey, key, value, colorsReplace[replaceKey])

		replacements[key] = value;
	}

	return replacements;
}

export const svgDataUriToXML = (svg) => {
	if (svg.startsWith('http')) {
		return svg;
	}

	if (svg.indexOf('data:image/svg+xml;base64,') !== -1) {
		svg = svg.split('base64,')[1];
	}

	svg = Buffer.alloc(svg.length, svg, 'base64').toString().match(/<svg[^>]*>(.|\n)*<\/svg>/g)[0];

	return svg;
}

export const svgColorToRGBA = (svg) => {
	let svgXML = svgDataUriToXML(svg);

	// console.log('svgColorToRGBA', svgXML);

	if (svgXML.indexOf('fill="') !== -1) {
		svgXML.match(/fill="[^"]+"/g).forEach((fill) => {
			let fillColor = fill.replace('fill="', '').replace('"', '');

			svgXML = svgXML.replaceAll(`fill="${fillColor}"`, `fill="${colorToRGBA(fillColor)}"`);
		});
	}

	if (svgXML.indexOf('stroke="') !== -1) {
		svgXML.match(/stroke="[^"]+"/g).forEach((stroke) => {
			let strokeColor = stroke.replace('fill="', '').replace('"', '');

			svgXML = svgXML.replaceAll(`stroke="${strokeColor}"`, `stroke="${colorToRGBA(strokeColor)}"`);
		});
	}

	return 'data:image/svg+xml;base64,' + Buffer.from(svgXML).toString('base64');
}

export const colorNameToHex = (colorName) => {
	const colorNames = {
		"aliceblue": "#F0F8FF",
		"antiquewhite": "#FAEBD7",
		"aqua": "#00FFFF",
		"aquamarine": "#7FFFD4",
		"azure": "#F0FFFF",
		"beige": "#F5F5DC",
		"bisque": "#FFE4C4",
		"black": "#000000",
		"blanchedalmond": "#FFEBCD",
		"blue": "#0000FF",
		"blueviolet": "#8A2BE2",
		"brown": "#A52A2A",
		"burlywood": "#DEB887",
		"cadetblue": "#5F9EA0",
		"chartreuse": "#7FFF00",
		"chocolate": "#D2691E",
		"coral": "#FF7F50",
		"cornflowerblue": "#6495ED",
		"cornsilk": "#FFF8DC",
		"crimson": "#DC143C",
		"cyan": "#00FFFF",
		"darkblue": "#00008B",
		"darkcyan": "#008B8B",
		"darkgoldenrod": "#B8860B",
		"darkgray": "#A9A9A9",
		"darkgrey": "#A9A9A9",
		"darkgreen": "#006400",
		"darkkhaki": "#BDB76B",
		"darkmagenta": "#8B008B",
		"darkolivegreen": "#556B2F",
		"darkorange": "#FF8C00",
		"darkorchid": "#9932CC",
		"darkred": "#8B0000",
		"darksalmon": "#E9967A",
		"darkseagreen": "#8FBC8F",
		"darkslateblue": "#483D8B",
		"darkslategray": "#2F4F4F",
		"darkslategrey": "#2F4F4F",
		"darkturquoise": "#00CED1",
		"darkviolet": "#9400D3",
		"deeppink": "#FF1493",
		"deepskyblue": "#00BFFF",
		"dimgray": "#696969",
		"dimgrey": "#696969",
		"dodgerblue": "#1E90FF",
		"firebrick": "#B22222",
		"floralwhite": "#FFFAF0",
		"forestgreen": "#228B22",
		"fuchsia": "#FF00FF",
		"gainsboro": "#DCDCDC",
		"ghostwhite": "#F8F8FF",
		"gold": "#FFD700",
		"goldenrod": "#DAA520",
		"gray": "#808080",
		"grey": "#808080",
		"green": "#008000",
		"greenyellow": "#ADFF2F",
		"honeydew": "#F0FFF0",
		"hotpink": "#FF69B4",
		"indianred": "#CD5C5C",
		"indigo": "#4B0082",
		"ivory": "#FFFFF0",
		"khaki": "#F0E68C",
		"lavender": "#E6E6FA",
		"lavenderblush": "#FFF0F5",
		"lawngreen": "#7CFC00",
		"lemonchiffon": "#FFFACD",
		"lightblue": "#ADD8E6",
		"lightcoral": "#F08080",
		"lightcyan": "#E0FFFF",
		"lightgoldenrodyellow": "#FAFAD2",
		"lightgray": "#D3D3D3",
		"lightgrey": "#D3D3D3",
		"lightgreen": "#90EE90",
		"lightpink": "#FFB6C1",
		"lightsalmon": "#FFA07A",
		"lightseagreen": "#20B2AA",
		"lightskyblue": "#87CEFA",
		"lightslategray": "#778899",
		"lightslategrey": "#778899",
		"lightsteelblue": "#B0C4DE",
		"lightyellow": "#FFFFE0",
		"lime": "#00FF00",
		"limegreen": "#32CD32",
		"linen": "#FAF0E6",
		"magenta": "#FF00FF",
		"maroon": "#800000",
		"mediumaquamarine": "#66CDAA",
		"mediumblue": "#0000CD",
		"mediumorchid": "#BA55D3",
		"mediumpurple": "#9370DB",
		"mediumseagreen": "#3CB371",
		"mediumslateblue": "#7B68EE",
		"mediumspringgreen": "#00FA9A",
		"mediumturquoise": "#48D1CC",
		"mediumvioletred": "#C71585",
		"midnightblue": "#191970",
		"mintcream": "#F5FFFA",
		"mistyrose": "#FFE4E1",
		"moccasin": "#FFE4B5",
		"navajowhite": "#FFDEAD",
		"navy": "#000080",
		"oldlace": "#FDF5E6",
		"olive": "#808000",
		"olivedrab": "#6B8E23",
		"orange": "#FFA500",
		"orangered": "#FF4500",
		"orchid": "#DA70D6",
		"palegoldenrod": "#EEE8AA",
		"palegreen": "#98FB98",
		"paleturquoise": "#AFEEEE",
		"palevioletred": "#DB7093",
		"papayawhip": "#FFEFD5",
		"peachpuff": "#FFDAB9",
		"peru": "#CD853F",
		"pink": "#FFC0CB",
		"plum": "#DDA0DD",
		"powderblue": "#B0E0E6",
		"purple": "#800080",
		"rebeccapurple": "#663399",
		"red": "#FF0000",
		"rosybrown": "#BC8F8F",
		"royalblue": "#4169E1",
		"saddlebrown": "#8B4513",
		"salmon": "#FA8072",
		"sandybrown": "#F4A460",
		"seagreen": "#2E8B57",
		"seashell": "#FFF5EE",
		"sienna": "#A0522D",
		"silver": "#C0C0C0",
		"skyblue": "#87CEEB",
		"slateblue": "#6A5ACD",
		"slategray": "#708090",
		"slategrey": "#708090",
		"snow": "#FFFAFA",
		"springgreen": "#00FF7F",
		"steelblue": "#4682B4",
		"tan": "#D2B48C",
		"teal": "#008080",
		"thistle": "#D8BFD8",
		"tomato": "#FF6347",
		"turquoise": "#40E0D0",
		"violet": "#EE82EE",
		"wheat": "#F5DEB3",
		"white": "#FFFFFF",
		"whitesmoke": "#F5F5F5",
		"yellow": "#FFFF00",
		"yellowgreen": "#9ACD32",
	};

	if (!colorNames.hasOwnProperty(colorName.toLowerCase())) {
		throw new Error('Invalid color name');
	}

	const hexColor = colorNames[colorName.toLowerCase()];

	return hexColor;
}
