import axios from 'axios';
import { DESIGN_API_URL } from '../../lib/environment.js';

const tagNamespaces = () =>  {

	return {
		read: () => {
			return new Promise((resolve, reject) => {

				const controller = new AbortController();

				let timeout = setTimeout(()=>{
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tagNamespaces`
					, method: 'GET'
					, cache: 'no-cache'
					, signal: controller.signal
				})
				.then(resp => {
					clearTimeout(timeout);

					if(resp.data.status === 0){
						resolve(resp.data.result)
					}else{
						reject(resp.data.result);
					}
				})
				.catch(err => {
					clearTimeout(timeout);

					reject(err);
				});
			});
		},
	}
};

const singleton = tagNamespaces();
Object.freeze(singleton);

export default singleton;