import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

const initialState = null;

export const templatesSlice = createSlice({
	name: 'templates',
	initialState,
	reducers: {
		setTemplatesState: (state, action) => {
			//null is not a valid
			if(!action.payload){
				return initialState;
			}else{
				return action.payload;
			}
		},
		updateTemplatesState: (state, action) => {
			//null is not a valid
			if(!action.payload){
				return [...state];
			}else{
				if(!Array.isArray(state)){
					return [...action.payload];
				}else{
					let newState = uniqBy([...state, ...action.payload], '_id');

					/* action.payload.forEach((item) => {
						let index = newState.findIndex((stateItem) => stateItem._id === item._id);
						if(index !== -1){
							console.log('updateTemplatesState', item._id);
							newState[index] = item;
						}else{
							newState.push(item);
						}
					}); */

					return newState;
				}
			}
		},
		resetTemplatesState: (state, action) => {
			return initialState;
			// console.log('Project Remove', action.payload)
		}
	}
});

// Action creators are generated for each case reducer function
export const { setTemplatesState, updateTemplatesState, resetTemplatesState } = templatesSlice.actions;

export const getTemplatesState = (state) => state.templates
export const getDefaultTemplatesState = () => initialState

export default templatesSlice.reducer;
